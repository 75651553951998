import React, { PropsWithChildren } from "react";

import { FieldWrapperProps } from "./types";
import * as Styled from "./styles";

const FieldWrapper: React.FC<
  PropsWithChildren<FieldWrapperProps>
> = ({
  label,
  labelAsSpan,
  disabled,
  error,
  suffix,
  labelOpacity,
  labelColor,
  required = false,
  children
}) => {
  const field = suffix ? (
    <Styled.FieldWrapper>
      {children}
      <Styled.Suffix>{suffix}</Styled.Suffix>
    </Styled.FieldWrapper>
  ) : (
    children
  );

  const labelComponent = label ? (
    <Styled.LabelText opacity={labelOpacity} color={labelColor}>
      {label}
      {required && <Styled.Asterisk>*</Styled.Asterisk>}
    </Styled.LabelText>
  ) : null;

  const main = (
    <>
      {labelComponent}
      {field}
      {error && <Styled.Error>{error}</Styled.Error>}
    </>
  );

  return label ? (
    <Styled.Label
      as={labelAsSpan ? "span" : "label"}
      withLabel={!!label}
      {...{ disabled }}
    >
      {main}
    </Styled.Label>
  ) : (
    main
  );
};

export default FieldWrapper;
